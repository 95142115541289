header {
    display : flex;
    align-items: center;
    justify-content : center;
    text-decoration: none;
    position: fixed;
    z-index: 100;
}

nav, .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.page { 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.tab {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    padding: calc(1 * var(--scale));
}

.widget {
    box-shadow: 0 0 0.5rem var(--softGrey);

    --padding : calc(1 * var(--scale));
    --border-radius: calc(0.4 * var(--scale));

    padding: var(--padding);
    border-radius: calc(var(--border-radius) + var(--padding));
    margin-bottom: var(--scale);
}

footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width : 800px), (orientation : landscape) {
    header {
        bottom: auto;
        top: 0;
    }
}
