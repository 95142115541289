#SignIn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: thistle;
}

#SignIn .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--scale);
    margin: var(--scale);
    border-radius: var(--scale);

    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

#SignIn form {
    margin-top: calc(3 * var(--scale));
    margin-bottom: calc(2 * var(--scale));
}

#SignIn .back {
    margin: 0;
    padding: calc(0.5 * var(--scale)) calc(2 * var(--scale));
    width: fit-content;
}

.button-signin {
    margin: 0;
}

.submit {
    width: 100%;
    margin: var(--scale) 0;
    background-color: var(--darkThistle);
    margin-top: calc(2 * var(--scale));
}

.submit span {
    color: white;
    font-size: calc(2 * var(--scale));
}

.submit:hover {
    background-color: var(--softThistle);
}
