.attendees {
    padding: var(--scale);
}

.attendee-container {
    display: flex;
    align-items: center;
    margin: var(--scale) 0;
    outline: calc(0.2 * var(--scale)) solid var(--softGrey);
    border-radius: var(--scale);
}

.attendee input {
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-family: 'Nunito';
}
