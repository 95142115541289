.form-informations {
    margin-bottom: calc(3 * var(--scale));
}

#FillForm .description {
    color: var(--softGrey);
    font-size: calc(2.5 * var(--scale));
}

#FillForm .input {
    display: flex;
    flex-direction: column;
    margin-bottom: calc(3 * var(--scale));
}

#FillForm .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--scale);
}

#FillForm .head span {
    color: white;
    width: 2rem;
    height: 2rem;
    text-align: center;
    line-height: 2rem;
    border-radius: 100%;
    margin-right: var(--scale);
}

#FillForm .input label {
    font-size: calc(2 * var(--scale));
}

#FillForm input {
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    box-shadow: 0 0 0.5rem var(--softGrey);
}

#FillFormByLink {
    /* display: flex; */
    width: 100%;
    height: 100%;
    padding: var(--scale);
    background-color: var(--softOrange);
}

.submit-button {
    background-color: grey;
}