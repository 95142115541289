.sides {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--scale);
    --margin: calc(var(--scale));
}

.background {
    z-index: 0;
    margin-bottom: 0;
}

.sides .input-container {
    width: auto;
}

#MirrorForm .section {
    grid-column: 1 / 3;
    border: solid calc(0.3 * var(--scale)) var(--darkThistle);
    z-index: 2000;
    pointer-events: none;
    border-radius: var(--scale);
}

.left {
    margin-left: var(--scale);
    z-index: 1000;
}

.right {
    margin-right: var(--scale);
    z-index: 1000;
}

.foreground {
    z-index: 10000;
}

.foreground.left {
    margin: var(--margin);
    margin-left: calc(var(--scale) + var(--margin));
}

.foreground.right {
    margin: var(--margin);
    margin-right: calc(var(--scale) + var(--margin));
}
