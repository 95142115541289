#SignUp {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    background-color: thistle;
}

#SignUp .container {
    --padding : calc(2 * var(--scale));
    --border-radius: calc(2 * var(--scale));

    margin: var(--padding);
    padding: var(--padding);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: calc(var(--border-radius) + var(--padding));
}

#SignUp .text {
    margin: 0 var(--padding);
}

#SignUp img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    border-radius: var(--border-radius);
}

form > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: var(--scale) 0;
    border-radius: var(--scale);
}

.sub-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.sub-form-input:nth-child(even) {
    margin-left: var(--scale);
}

.error {
    color: red;
    font-size: calc(2 * var(--scale));
}

form input {
    width: 100%;
    border: none;
    font-size: calc(2 * var(--scale));
    border-radius: 100vh;
    padding: var(--scale);
    background-color: var(--softOrange);
}

.button-signup {
    margin: 0;
}

.submit-signup {
    width: 100%;
    margin: calc(2 * var(--scale)) 0;

    font-size: calc(2.5 * var(--scale));
}


@media (min-width : 800px), (orientation : landscape) {
    #SignUp .container {
        flex-direction: row;
    }

    #SignUp img {
        width: 50%;
        height: 50vh;
    }
}
