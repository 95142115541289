.selector-container {
    display: flex;
    justify-content: center;
    --borderRadius: calc(1 * var(--scale));
    --padding: calc(1 * var(--scale));
}

.selector {
    display: flex;
    justify-content: center;
    border: solid calc(0.1 * var(--scale)) var(--softGrey);
    background-color: white;
    border-radius: calc(var(--borderRadius) + var(--padding));
}

.selector span {
    margin: var(--padding);
    padding: calc(1 * var(--scale));
    border-radius: var(--borderRadius);
    cursor: pointer;
}

.selector span.selected {
    color: white;
}
