.slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
}

.slider .title {
    color: var(--darkThistle);
    padding: calc(3 * var(--scale));
}

.indicator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    background-color: thistle;
    width: fit-content;
    --size : calc(1 * var(--scale));
    --margin : calc(2 * var(--scale));
    border-radius: calc(var(--size) + var(--margin));
    padding: var(--margin) calc(var(--margin) / 2);
}

.indicator div {
    border-radius: var(--size);
    margin: 0 calc(var(--margin) / 2);
    height: var(--size);
    min-width: var(--size);
    background-color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
}

.cards::-webkit-scrollbar {
    display: none;  /* Masquer la barre de défilement sur Chrome, Safari et Opera */
}

.cards {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: none;
    width: 100vw;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 calc(3 * var(--scale));
}

.cards .card {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(80 * var(--scale));
    width: calc(60 * var(--scale));
    max-height: 70vh;
    max-width: 90vw;
    margin: var(--scale);
    border-radius: var(--scale);
    background-color: thistle;
}

.cards .card .text {
    display: flex;
    flex-direction: column;
    width : 100%;
    height: 100%;
    padding: var(--scale);
}

.cards .card:nth-child(even) .text {
    justify-content: flex-end;
}

.cards .card h2 {
    font-size: calc(4 * var(--scale));
    font-weight: 500;
}

.cards .card h3 {
    font-size: calc(3 * (var(--scale)));
    color: var(--darkThistle);
    font-style: italic;
}

.cards .card p {
    font-family: "Nunito";
    font-size: calc(2 * var(--scale));
}
