/* -------------- General -------------- */

* {
    color : black;
}

:root{
    --purple : #ae49fc;
    --transprentPurple : rgba(174, 73, 252, 0.6);

    --darkThistle : rgb(96, 87, 189);
    
    --red : #FF0000;
    --softRed : #D20000;
    --transparentRed : rgba(255, 0, 0, 0.4);

    --green : #61D836;

    --darkGrey : rgb(18, 18, 18);
    --grey : rgb(115, 115, 115);
    --limitedGrey : rgb(140, 140, 140);
    --softGrey : rgb(206, 206, 206);
    --backgroundGrey: rgb(247, 247, 247);
    --transparentGrey : rgba(115, 115, 115, 0.2);

    --softOrange : rgb(253, 224, 207);
    --orange : rgb(243, 187, 101);
    --darkOrange : rgb(255, 165, 0);
}

.tab {
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.widget {
    background-color: white;
}



/* -------------- Icons -------------- */

.cross {
    fill: white;
}

.icon-container {
    backdrop-filter: blur(10px);
    background-color: var(--softGrey);
}

.button {
    background-color: orange;
}

.button span {
    color: white;
}

.link-button {
    border: calc(0.2 * var(--scale)) solid var(--softGrey);
    background-color: transparent;
}

.link-button:hover {
    background-color: var(--orange);
}

.link-button:hover span {
    color: white;
}

.link-button span {
    color: black;
}

/* -------------- Form -------------- */


/* -------------- Attendees -------------- */


/* -------------- Graph -------------- */

.pie-graph circle {
    fill: transparent;
}

.pie-graph .background-donut {
    stroke: var(--softGrey);
}

.pie-graph .foreground-donut {
    stroke: var(--green);
}

.pie-graph text {
    fill: var(--green);
}

