#Forms .container {
    padding: var(--scale);
}

.form-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: var(--softOrange);

    margin: var(--scale);
    padding: var(--scale);

    border-radius: var(--scale);
}

.form-summary h2 {
    font-size: calc(3 * var(--scale));
}

.form-summary p {
    font-size: calc(2 * var(--scale));
}

.form-summary .button {
    background-color: red;
}

.form-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: var(--scale);
    border-radius: var(--scale);
}

.form-status-container p {
    font-size: calc(2 * var(--scale));
    color: var(--softOrange);
    font-style: italic;
}
