.informations {
    display: flex;
    flex-direction: column;
    background-color: var(--backgroundGrey);
    border-radius: var(--border-radius);
}

.information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: calc(0.5 * var(--scale));
}

.information .value {
    color: var(--limitedGrey);
} 

.information:not(:last-child) {
    border-bottom: solid calc(0.1 * var(--scale)) var(--softGrey);
}
