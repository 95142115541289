/* -------------- General -------------- */

body, #root {
    position: absolute;
    margin: 0;
    padding: 0;
    padding-bottom: 20px; /* Valeur par défaut */
    padding-bottom: constant(safe-area-inset-bottom); /* Ancienne syntaxe pour iOS 11 */
    padding-bottom: env(safe-area-inset-bottom); /* Nouvelle syntaxe pour iOS 12+ */

    --scale: calc((1vh + 1vw) / 2);

    --nav_height: calc(9 * var(--scale));
    --header_height: calc(var(--nav_height) + 2 * var(--scale));
}

h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: 'Nunito';
}

h1 {
    font-size: calc(var(--scale) * 6);
}

h2 {
    font-size: calc(var(--scale) * 5);
}

p {
    margin: 0;
    font-size: calc(var(--scale) * 4);
}

header {
    width: 100%;
    height: var(--header_height);
    bottom: 0;
    padding: calc(2 * var(--scale));
}

footer {
    margin-bottom: var(--header_height);
}

.page {
    width: 100vw;
    height: 100vh;
}

.tab {
    width: 100%;
    margin: calc(var(--scale));
    border-radius: calc(var(--scale));
}

/* -------------- Icons -------------- */

.icon-container {
    border-radius: 100vh;
}

.button {
    border-radius: 100vh;
    padding: calc(var(--scale) * 1) calc(var(--scale) * 2);
    margin: calc(var(--scale));
    border: none;
}


/* -------------- Form -------------- */

#Form {
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* -------------- Media Queries -------------- */


/* -------------- Graph -------------- */

.graph-container {
    border-radius: var(--scale);
}

.graph-container .description-container {
    border-radius: calc(var(--scale));
    width: 100%;
    height: 100%;
}

.graph-container > .description-container > .description h3 {
    padding-bottom: calc(2 * var(--scale));
}

.graph-container > .description-container > .description h4 {
    padding-bottom: calc(1.5 * var(--scale));
}

.pie-graph {
    --size: calc(4 * var(--scale));
    height: var(--size);
    width: var(--size);
    margin: var(--scale);
}

.pie-graph circle {
    stroke-width: 12;
}

