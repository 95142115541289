.switch-container {
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    padding: calc(0.2 * var(--scale));

    --height: calc(2 * var(--scale));

    width: calc(2 * var(--height));
    background-color: var(--softGrey);
    border-radius: calc(2 * var(--height));
}

.switch-container.checked {
    justify-content: flex-end;
    background-color: var(--green);
}

.switch {
    height: var(--height);
    width: var(--height);

    border-radius: var(--height);
    margin: 0;

    background-color: white;
}
