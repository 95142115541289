#DashBoard {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    background: linear-gradient(135deg, var(--softOrange) 0%, thistle 50%, var(--darkThistle) 100%);
}

#Placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

#Placeholder .widget {
    width: 100%;
    height: 100%;
    padding: 0;
    margin-bottom: 0;
    overflow: hidden;
}

#Placeholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bloc {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin: calc(1 * var(--scale)) 0;
    border-radius: calc(2 * var(--scale));
    padding: calc(1 * var(--scale));
}
