.volumetry .controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
}

.chart {
    display: flex;
    width: 100%;
    flex-grow: 1;
    position: relative;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--transparentGrey);
    backdrop-filter: blur(5px);
    z-index: 1;
}

.filters-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
}

.filters-container .selector-container {
    justify-content: flex-start;
}

.filters-container > div {
    margin: calc(0.5 * var(--scale));
}

.filters-container .selector {
    --padding : calc(0.5 * var(--scale));
}

.filters-container .selector span {
    padding: calc(0.5 * var(--scale)) calc(1 * var(--scale));
}

.filter {
    display: flex;
    flex-direction: column;
    --height-switch: calc(2 * var(--scale));
    --padding: calc(0.5 * var(--scale));
}

.filter .switch-container {
    --height: var(--height-switch);
}

.filter-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding);
    margin: calc(0.5 * var(--scale)) 0;
    background-color: var(--backgroundGrey);
    border-radius: calc(var(--height-switch) / 2 - var(--padding));
}

.range {
    display : flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: auto;
    padding: calc(1 * var(--scale));
    outline: calc(0.1 * var(--scale)) solid var(--softGrey);
    border-radius: calc(0.5 * var(--scale));
}
