#Form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: calc( 1 * var(--scale));
    padding-bottom: 0;
    width: 100%;
    margin: 0;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
}

#Form .widget {
    width: 100%;
}

#Form .description {
    display: flex;
    flex-direction: column;
}

.header-container .header {
    display: flex;
    flex-direction: column;
}

.header-container h1 {
    font-size: calc(3 * var(--scale));
}

.settings {
    display: flex;
}

.link-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#Form .informations {
    width: 30%;
}


.info-popup {
    position: absolute;
    display: flex;
    flex-direction: column;
    max-width: calc(10 * var(--scale));
    background-color: var(--softGrey);
    border: 1px solid var(--darkGrey);
    padding: calc(0.5 * var(--scale));
    border-radius: calc(0.5 * var(--scale));
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100000;
    pointer-events: none; /* Empêche la popup d'interférer avec les événements de la souris */
}

.info-popup p {
    font-size: var(--scale);
}

.selected {
    background-color: var(--darkThistle);
}

.unselected {
    background-color: var(--softGrey);
}

.deactivate {
    opacity: 0.5;
    background-color: grey;
} 

.deactivate * {
    color: grey;
}

.icon-container {
    margin: 0;
    margin-right: calc(0.5 * var(--scale));
}

/* ---------------- Size ---------------- */

.size {
    display: flex;
    flex-direction: column;
}

.size .selectors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.size .size-mode-selector {
    --padding: calc(0.5 * var(--scale));
    --border-radius : calc(0.5 * var(--scale));
}

.size .size-mode-selector span {
    padding: var(--padding);
    font-size: calc(1.2 * var(--scale));
}

.size .inventory {
    display: grid;
    border-radius: var(--border-radius);
    overflow: hidden;
    margin-top: calc(var(--scale));
    background-color: var(--backgroundGrey);
}

.size .inventory .inventory-header {
    background-color: var(--softGrey);
}

.size .inventory .inventory-line {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

.size .inventory .inventory-line:not(:last-child) {
    border-bottom: calc(0.3 * var(--scale)) solid white;
}

.size .inventory .inventory-line span {
    padding: calc(0.5 * var(--scale));
}

.size .inventory .inventory-line span:not(:last-child) {
    border-right: calc(0.3 * var(--scale)) solid white;
}

.size .inventory .inventory-line span:first-child {
    grid-column: 1;
}

.size .inventory .inventory-line span:last-child {
    grid-column: 2;
}
