#CSVParser {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    padding: calc(1 * var(--scale));
}

#CSVParser .questions .informations {
    width: 30%;
    margin-bottom: var(--scale);
}

.widget.upload {
    display: flex;
    flex-direction: row;
}

.widget.filed {
    background-color: var(--backgroundGrey);
}

.zone {
    display: flex;
    align-items: center;
    border-width: 2;
    border-style: dashed;
    border-color: var(--softGrey);
    flex-direction: column;
    justify-content: center;
    padding: var(--padding);
    border-radius:  var(--border-radius);
    width: 100%;
}

.zone.filed {
    width: auto;
}

.zone:hover {
    border-color: var(--softOrange);
}

.zone .upload-logo {
    height: calc(10 * var(--scale));
}

.zone .upload-logo path {
    fill: var(--softGrey);
}

.informations.upload {
    height: fit-content;
    background-color: white;
    width: 100%;
    margin-left: var(--scale);
}

.file {
    display: flex;
    flex-direction: row;
    justify-content: center;

    /* background: linear-gradient(var(--softOrange), thistle); */
    background-color: white;
    border: calc(0.1 * var(--scale)) solid var(--darkThistle);

    padding: var(--scale);
    border-radius: var(--scale);
}

.file .left {
    width: calc(5 * var(--scale));
    height: 100%;
    margin-right: var(--scale);
}

.file .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.info {
    display: flex;
    flex-direction: column;
    margin-right: var(--scale);
    justify-content: center;
}

.info span {
    color: var(--limitedGrey);
}

.size {
    justify-content: center;
    display: flex;
}

.default {
    border-color: var(--softGrey);
}

.checkbox-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.head {
    display: flex;
    flex-direction: column;
    padding: calc(1 * var(--scale));
}
