* {
    box-sizing: border-box;
    --smartphone-size : 800px;
    text-decoration: none;
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    top: 0;
}
  
body, #root {
    position: relative;
    display: flex;
    flex-direction : column;
    overflow: hidden;
    font-family: 'Nunito';
}

#root {
    overflow-y: scroll;
    overscroll-behavior: none;
}

@font-face {
	font-family : 'Tune';
	src : url('../fonts/tune.ttf');
}

@font-face {
	font-family: 'Nunito';
	src: url('../fonts/Nunito-2/Nunito-VariableFont_wght.ttf');
}
