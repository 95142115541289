#Landing {
    display: flex;
    flex-direction: column;
    /* background: var(--softOrange); */
    box-shadow: none;
    width: 100vw;
    overflow-y: auto;
    padding-top: var(--nav-height)
}

#Landing h1 {
    color: white;
}

#Landing > h2 {
    margin-left: calc(2 * var(--scale));
    margin-top: calc(10 * var(--scale));
}

#Landing .hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: calc(3 * var(--scale)) calc(3 * var(--scale)) calc(5 * var(--scale)) calc(3 * var(--scale));
}

#Landing .hero {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;

    --borderRadius: calc(2 * var(--scale));
    --padding : calc(3 * var(--scale));

    background-color: thistle;
    padding: var(--padding);
    border-radius: calc(var(--borderRadius) + var(--padding));
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

#Landing .hero .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#Landing .hero h1 {
    color: white;
}

#Landing .hero h2 {
    color: var(--darkThistle);
    /* font-style: italic; */
    font-size: calc(var(--scale) * 2);
}

#Landing .hero .signup {
    background-color: var(--orange);
}

#Landing .hero .signup:hover {
    background-color: orange;
}

#Landing .hero .signup * {
    color: white;
    font-size: calc(var(--scale) * 2);
}


#Landing .hero img {
    width: 100%;
    height: 65%;
    object-fit: cover;
    object-position: top;
    border-radius: var(--borderRadius);
}

.use-cases-slider .card {
    background-color: white;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
}

.use-cases-slider .card .text {
    display: flex;
    justify-content: flex-start !important;
}

.usecases-container {
    margin: calc(10 * var(--scale)) 0;
}

.use-case {
    display: flex;
    flex-direction: row;
    height: 80%;
    margin: calc(1 * var(--scale));
    padding: calc(2 * var(--scale));
    border-radius: calc(var(--scale));

    background-color: thistle;
    border: calc(0.1 * var(--scale)) solid var(--darkThistle);
}

.use-case.special {
    background-color: var(--softOrange);
    border: calc(0.1 * var(--scale)) solid var(--darkOrange);
}

.use-case.special span {
    color: var(--darkOrange);
}

.use-case span {
    color: var(--darkThistle);
    font-size: calc(var(--scale) * 1.5);
    width: 100%;
    text-align: left;
}

/* -------------- Pricing -------------- */

.pricing-container {
    display: flex;
    flex-direction: column;
    
    
}

.plan-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: calc(2 * var(--scale)) 0;
    width: 100%;
}

.pricing-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: calc(2 * var(--scale));
    padding: calc(2 * var(--scale));
    border: solid calc(0.1 * var(--scale)) var(--softGrey);
    width: 80%;
    margin: calc(1 * var(--scale)) 0;
}

.pricing-card h2 {
    font-size: calc(var(--scale) * 3);
}

.period-selector {
    margin-top: calc(1 * var(--scale));
}

.period-selector span.selected {
    background-color: orange;
}

.pricing-card .price-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: calc(1 * var(--scale)) 0;
}

.price {
    font-size: calc(var(--scale) * 2);
    font-weight: 600;
}

.crossed-out {
    text-decoration: line-through;
    margin-right: calc(0.5 * var(--scale));
}

.personae {
    background-color: var(--backgroundGrey);
    padding: 10% calc(2 * var(--scale)) ;
}

.personae h2 span {
    color: orange;
    font-weight: 600;
}

.personae p {
    font-size: calc(var(--scale) * 1.5);
}

.personae p.description {
    font-size: calc(var(--scale) * 2.5);
    color: var(--grey);
}

.personae h3 {
    font-size: calc(var(--scale) * 2.5);
}

.personae h4 {
    font-size: calc(var(--scale) * 2);
    color: var(--darkThistle);
}

.personae .sections {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6%;
}

.personae .section {
    --padding : calc(2 * var(--scale));
    --borderRadius: calc(1 * var(--scale));

    display: flex;
    flex-direction: column;
    margin: calc(2 * var(--scale));
    padding: var(--padding);
    border-radius: calc(var(--borderRadius) + var(--padding));

    background-color: white;
    width: 80%;
}

.personae .section .feature {
    margin: calc(1 * var(--scale)) 0;
    padding: calc(1 * var(--scale));
    border-radius: var(--borderRadius);
    border: solid calc(0.1 * var(--scale)) var(--softGrey);
}

.personae .feature:nth-last-child(1) {
    margin-bottom: 0;
}


@media (min-width : 800px), (orientation : landscape) {
    #Landing .hero {
        flex-direction: row;
        height: 80vh;
    }

    #Landing .hero .text {
        padding-right: var(--padding);
    }

    #Landing .hero .signup {
        width: fit-content;
        margin-left: 0;
        padding-left: calc(4 * var(--scale));
        padding-right: calc(4 * var(--scale));
    }

    #Landing .hero img {
        width: 40%;
        height: 100%;
    }

    .plan-container {
        flex-direction: row;
    }

    .pricing-card {
        height: 100%;
        margin: 0 calc(2 * var(--scale));
    }

    .personae .sections {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 6%;
    }

    .personae .section {
        width: 40%;
    }
}
