.card.widget {
    --border-radius: calc(1 * var(--scale));
}

.card {
    display: flex;
    flex-direction: column;
    margin: calc(2 * var(--scale));
}

.card img {
    /* max-width: 40vw; */
    height: 40vh;
    object-fit: cover;
    border-radius: var(--border-radius);
    border: calc(0.1 * var(--scale)) solid var(--darkThistle);
}

.card .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: calc(2 * var(--scale));
}

.card h3 {
    font-size: calc(3.2 * var(--scale));
}

.card h4 {
    font-size: calc(2.5 * var(--scale));
    color: var(--darkThistle);
}

.card p {
    font-size: calc(1.8 * var(--scale));
    margin: calc(1 * var(--scale)) 0;
}

.card .date {
    font-size: calc(1.6 * var(--scale));
    color: var(--orange);
}

.card .tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(1 * var(--scale));
}

.card .tag {
    font-size: calc(1.2 * var(--scale));
    background-color: var(--darkThistle);
    margin-right: calc(0.3 * var(--scale));
    margin-bottom: calc(0.3 * var(--scale));
    padding: calc(0.5 * var(--scale)) calc(1 * var(--scale));
    border-radius: calc(100 * var(--scale));
    color: white;
}

/* tous sauf le premier de chaque ligne */
.card .tag:not(:nth-child(1)) {
    margin-left: calc(0.5 * var(--scale));
}


@media (min-width : 800px), (orientation : landscape) {
    .card {
        display: flex;
        flex-direction: row;
    }

    .card img {
        min-width: 40vw;
        height: 40vh;
    }

    .card .description {
        padding: 0 calc(2 * var(--scale));
    }
}