footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

    font-family: 'Nunito';

    width: 100vw;
    margin-top: 2vh;
}

footer * {
    width: 100%;
    
}

footer #sections {
    padding: calc(3 * var(--scale)) 0;
    max-width: 80%;
    display: flex;
    justify-content: space-between;
}

footer h2 {
    margin-bottom: var(--scale);
}

.section-footer .page-footer h3 {
    margin: var(--scale) 0;
}

.section-footer .page-footer:hover h3 {
    text-decoration: underline;
}

.page-footer-developed-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    width : auto;

    position: fixed;
    z-index: 1000;

    padding: calc(2 * var(--scale));
    backdrop-filter: blur(10px);

    animation: blur 0.4s ease-in-out;
}

.page-footer-developed-container h1 {
    font-size: calc(5 * var(--scale));
}

.page-footer-developed-container h2 {
    font-size: calc(4 * var(--scale));
    margin-top: calc(2 * var(--scale));
    margin-bottom: calc(1 * var(--scale));
}

.page-footer-developed-container h3 {
    font-size: calc(3 * var(--scale));
    margin: calc(2 * var(--scale)) 0;
}

.page-footer-developed-container h4 {
    font-size: calc(2 * var(--scale));
    margin: calc(2 * var(--scale)) 0;
    text-decoration: underline;
}


.section-footer .page-footer-developed {
    display: flex;
    flex-direction: column;

    height: 100vh;
    border-radius: calc(2 * var(--scale));
    padding: calc(3 * var(--scale));

    background-color: var(--orange);

    animation: slide-up 0.5s ease-in-out;

    overflow-y: scroll;
}

.page-footer-developed-container.hide {
    animation: blur-out 0.5s ease-in-out;
}

.page-footer-developed.hide {
    animation: slide-down 0.5s ease-in-out;
}

@keyframes slide-up {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slide-down {
    0% {
        transform: translateY(0);
        backdrop-filter: blur(10px);
    }
    100% {
        transform: translateY(100%);
        backdrop-filter: blur(0);
    }
}

@keyframes blur-in {
    0% {
        backdrop-filter: blur(0);
    }
    100% {
        backdrop-filter: blur(10px);
    }
}

@keyframes blur-out {
    0% {
        backdrop-filter: blur(10px);
    }
    100% {
        backdrop-filter: blur(0);
    }
}

.section-footer .cross-container {
    width: calc(3 * var(--scale));
    height: calc(3 * var(--scale));
    justify-self: flex-start;
    align-self: flex-end;
}


#copyright {
    border-top: 1px solid var(--softGrey);
    max-width: 80%;
    padding: calc(3 * var(--scale)) 0;
}
