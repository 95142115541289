.hide-selector {
    display: inline-block;
    position: relative;
    width: 100%;

    --padding: calc(0.5 * var(--scale));
    --padding2: calc(1 * var(--scale));
    --border-radius: calc(1 * var(--scale));
}

.hide-selector .options {
    position: absolute;
    margin-top: calc(0.5 * var(--scale));
    left: 0;
    right: 0;
    background-color: aliceblue;
    padding: var(--padding);
    border-radius: calc(var(--border-radius) + var(--padding));
    z-index: 100;
}

.hide-selector .option {
    padding: calc(0.5 * var(--scale));
    margin: var(--padding);
    border-radius: var(--border-radius);
}

.option.selected {
    background-color: thistle;
}

.highlighted {
    background-color: var(--softGrey);
}

.current {
    border: calc(0.1 * var(--scale)) solid var(--softGrey);
    padding: var(--padding2) calc(var(--padding) + var(--padding2));
    border-radius: calc(var(--border-radius) + var(--padding));
}
