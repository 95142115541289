#Results .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#Results .header {
    background-color: white;
    box-shadow: 0 0 0.5rem var(--softGrey);
    padding: var(--scale);
    width: 100%;
    margin-right: var(--scale);
}

#Results .header .nb-answers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: calc(1.5 * var(--scale));
    background-color: var(--backgroundGrey);
    width: fit-content;
    padding: calc(0.5 * var(--scale));
    border-radius: var(--border-radius);
}

.nb-answers span {
    margin-left: calc(1 * var(--scale));
}

.qrcode-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.qrcode-container.expanded {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    background-color: thistle;
    z-index: 100;

    height: 100vh;
    width: 100vw;
}

.qrcode-container.expanded .qrcode-svg {
    width: 80vw;
    height: 80vh;
}

.qrcode-svg {
    width: calc(12 * var(--scale));
    height: calc(12 * var(--scale));
}

.qrcode .controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: var(--scale) 0;
} 

.expand-button {
    display: flex;
    height: 100%;
    width: calc(3 * var(--scale));
    padding: calc(0.5 * var(--scale));
    background-color: thistle;
    border-radius: var(--border-radius);
}

.copy-button {
    margin: 0;
    margin-right: calc(0.5 * var(--scale));
    width: 100%;
    height: 100%;
}

.download-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border: solid calc(0.1 * var(--scale)) thistle;
    border-radius: var(--border-radius);
    padding: calc(0.5 * var(--scale));
}

.download-options .button {
    margin: 0;
    padding: calc(0.5 * var(--scale)) calc(1 * var(--scale));
}

.select-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: calc(0.5 * var(--scale));
}

.select-option label {
    font-size: calc(1 * var(--scale));
    margin-right: calc(0.5 * var(--scale));
}

.volumetry {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#Results h1 {
    font-size: calc(3 * var(--scale));
    color: var(--darkThistle);
}

#Results h2 {
    font-size: calc(4 * var(--scale));
}

#Results h3 {
    align-self: flex-start;
    font-weight: 600;
    font-size: calc(2.5 * var(--scale));
}

.groups-container {
    display: flex;
    flex-wrap: wrap;
}

.group {
    --padding: calc(1 * var(--scale));
    --border-radius: calc(0.5 * var(--scale));
    background-color: var(--softOrange);
    margin: var(--scale);
    padding: var(--scale);
    border-radius: var(--scale);
}

.group h4 {
    font-weight: 500;
    font-size: calc(1.5 * var(--scale));
}

.group .answer {
    padding: calc(0.5 * var(--scale));
    background-color: white;
    border-radius: var(--border-radius);
}

.group .answer:not(:last-child) {
    margin-bottom: calc(0.5 * var(--scale));
}

.group .answer .entrie {
    display: flex;
    flex-direction: column;
}

.group .answer .entrie .key {
    font-size: calc(0.7 * var(--scale));
    color: var(--darkThistle);
}


.toggle-switch {
    margin-bottom: 20px;
}

.toggle-switch label {
    cursor: pointer;
}

.toggle-switch input[type="checkbox"] {
    display: none;
}

.toggle-switch span {
    padding-left: 10px;
    font-size: 16px;
}

.compute-button {
    position: absolute;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 calc(1 * var(--scale)) var(--grey);
    z-index: 100;
}

.compute-button span {
    font-size: calc(1.5 * var(--scale));
}