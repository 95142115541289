#Blog {
    padding-top: var(--nav-height);
    overflow-y: auto;
}

#article {
    
    padding-top: var(--nav-height);
    overflow-y: auto;
}

#article .content {
    padding: 0 calc(20vw);
}

#article .content h1 {
    font-size: calc(3 * var(--scale));
    margin: calc(5 * var(--scale)) 0;
}

#article .content h2 {
    font-size: calc(2.5 * var(--scale));
    margin-top: calc(4 * var(--scale));
    margin-bottom: calc(2 * var(--scale));
    color: var(--darkThistle);
}

#article .content h3 {
    font-size: calc(2 * var(--scale));
    margin-top: calc(3 * var(--scale));
    margin-bottom: calc(1 * var(--scale));
    color: var(--darkOrange);
}

#article .content p {
    font-size: calc(1.8 * var(--scale));
    margin: calc(1 * var(--scale)) 0;
}

#article .content strong {
    font-weight: 600;
}
