.number-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.number-selector * {
    font-family: "Nunito";
}

/* Pour Chrome, Safari, Edge, Opera */
.number-selector input[type="number"]::-webkit-inner-spin-button,
.number-selector input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Pour Firefox */
.number-selector input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.number-selector input[type="number"] {
    text-align: center;
    border: calc(0.1 * var(--scale)) solid var(--softGrey);
    border-radius: calc(0.5 * var(--scale));
    font-size: calc(var(--scale) * 1.2);
}

.number-selector input[type="number"]:focus {
    outline: none;
    border-color: var(--darkThistle);
}

.number-selector .buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.number-selector button {
    margin: calc(var(--scale) * 0.2);
    font-size: calc(var(--scale) * 1.5);
    cursor: pointer;
    appearance: none;
    border: solid 1px var(--darkThistle);
    background-color: white;
    border-radius: calc(var(--scale) * 0.5);
    padding: 0 calc(var(--scale) * 1.5);
}

.number-selector button:hover {
    border: solid 1px var(--darkOrange);
}

.number-selector button:active {
    background-color: var(--softOrange);
}

.number-selector button span {
    display: flex;
    justify-content: center;
    align-items: center;
}
