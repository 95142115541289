.sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--backgroundGrey);
    padding: var(--scale);
    border-right: calc(0.1 * var(--scale)) solid var(--softGrey);
}

.sidebar h3 {
    font-size: calc(var(--scale) * 3);
}

.sidebar .recent-container {
    width: 100%;
}

.sidebar .recent {
    background-color: thistle;
    padding: calc(0.5 * var(--scale));
    border-radius: calc(0.5 * var(--scale));
    cursor: pointer;
}

.sidebar .recent:not(:last-child) {
    margin-bottom: calc(var(--scale));
}

.sidebar .recent:hover {
    background-color: var(--softOrange);
}

.sidebar-section {
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.sidebar-section h3 {
    padding: var(--scale);
    padding-bottom: 0;
}


.sidebar .line {
    padding: calc(0.8 * var(--scale)) var(--scale);
}

.sidebar-section .line:not(:last-child) {
    border-bottom: calc(0.1 * var(--scale)) solid var(--softGrey);
}

.sidebar-section .line:hover {
    background-color: var(--softOrange);
    cursor: pointer;
}
