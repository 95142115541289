#Vitrine {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100vw;
    --nav-height: calc(8 * var(--scale));
}

#Vitrine nav {
    display: flex;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 calc(2 * var(--scale));
    z-index: 100;
    height: var(--nav-height);
}

nav .nav-button {
    border: none;
}

#Vitrine .harmate-logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

#Vitrine .harmate-logo-container span {
    font-size: calc(2.5 * var(--scale));
    font-weight: 300;
    margin-left: calc(0.5 * var(--scale)); 
}

#Vitrine .harmate-logo-container .harmate-logo {
    transform: rotate(-45deg);
    height: calc(3 * var(--scale));
    width: auto;
}

#Vitrine .signin {
    background-color: var(--darkThistle)
}

#Vitrine .signin * {
    color: white;
}

.hero {
    padding: 0 calc(2 * var(--scale));
    margin-bottom: calc(4 * var(--scale));
}

.hero h2 {
    color: var(--darkThistle);
    font-size: calc(4 * var(--scale));
}
